<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="50vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-1">
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.role_id"
                  :busy="busy"
                  label="Role ID"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.name"
                  :busy="busy"
                  label="Name"
                  :hasClear="true"
                />
              </v-col> 
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.is_system"
                  :busy="busy"
                  label="Is System"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.shared_across_accounts"
                  :busy="busy"
                  label="Shared Across Platform"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="4" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.archived"
                  :busy="busy"
                  label="Archived"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                  :multiple="true" 
                />
              </v-col> 
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              sortable: false,
              value: 'row_no',
              type: 'int',
              width: 100,
            },
            {
              text: 'Account',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'name',
              type: 'slot',
            },
            {
              text: 'System',
              align: 'start',
              sortable: false,
              value: 'is_system',
              type: 'boolean',
            },
            {
              text: 'Shared',
              align: 'start',
              sortable: false,
              value: 'shared_across_accounts',
              type: 'boolean',
            },
            {
              text: 'Notes',
              align: 'start',
              sortable: false,
              value: 'notes',
              type: 'text',
            },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              value: 'created_by_name',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              sortable: false,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
            {
              text: 'Deleted Date',
              align: 'start',
              value: 'deleted_at',
              type: 'date-time',
            },
            {
              text: 'Deleted By',
              align: 'start',
              value: 'deleted_by_name',
              type: 'slot',
              width: '100'
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
          @on-refreshed="busy = false"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.account_name }} ({{ row.prop.item.account_id }})
            </div>
            <div
              v-if="row.prop.key === 'name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.name }} ({{ row.prop.item.role_id }})
            </div>
             <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'deleted_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="
              showInformation = false;
              reset();
            "
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12">
            <v-form
              v-if="action !== ''"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="8">
                  <o-input
                    v-model="model.name"
                    :busy="busy"
                    label="Name"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>
                <v-col cols="4">
                  <o-combo-box
                    v-model="model.account_id"
                    :busy="busy"
                    label="Accounts"
                    :items="accounts" 
                  />
                </v-col>
                <v-col cols="12">
                  <o-input v-model="model.notes" :busy="busy" label="Notes" />
                </v-col>
                
                <v-col cols="8">
                  <o-check-box 
                    v-model="model.archived"
                    :busy="busy"
                    label="Archived"
                    style="display:none"
                  />

                  <o-check-box
                    v-model="model.is_system"
                    :busy="busy"
                    label="System"
                    style="display:inline-block"
                  />

                  <o-check-box
                    v-model="model.shared_across_accounts"
                    :busy="busy"
                    label="Shared Across Platform"
                    style="display:inline-block"
                  />
                </v-col>

                <v-col cols="4" v-if="!model.is_system">
                  <o-combo-box
                    v-model="model.parent_role_id"
                    :busy="busy"
                    label="Parent Roles"
                    :items="parentRoles"
                    @on-change="fillPermissions()"
                  />
                </v-col>
                <v-col cols="12">
                  <v-subheader>
                    <v-col cols="6">
                      Permissions
                    </v-col>
                    <v-col cols="6">
                       <o-input v-model="permissionFilterValue" :busy="busy" label="Permission Filter" @on-value-change="permissionFilter" :hasClear="true" />
                    </v-col>
                  </v-subheader>
                  <o-table
                    ref="permissionTable"
                    :busy="busy"
                    type="array"
                    :fixed-header="true"
                    :height="getHeight(442)"
                    :data="permissionData"
                    :headers="[
                      {
                        text: 'OBJECT',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                        type: 'slot',
                      },
                      {
                        text: 'CREATE',
                        align: 'start',
                        sortable: false,
                        value: 'create',
                        type: 'edit-boolean',
                        action: 'on-check-changed',
                      },
                      {
                        text: 'READ',
                        align: 'start',
                        sortable: false,
                        value: 'read',
                        type: 'edit-boolean',
                        action: 'on-check-changed',
                      },
                      {
                        text: 'UPDATE',
                        align: 'start',
                        sortable: false,
                        value: 'update',
                        type: 'edit-boolean',
                        action: 'on-check-changed',
                      },
                      {
                        text: 'DELETE',
                        align: 'start',
                        sortable: false,
                        value: 'delete',
                        type: 'edit-boolean',
                        action: 'on-check-changed',
                      },
                    ]"
                    @on-check-changed="permissionCheckChanged"
                  >
                    <template v-slot:slot="row">
                      <div
                        v-if="row.prop.key === 'name'"
                      >
                        {{
                          row.prop.item.name
                        }}
                      </div>
                    </template>
                  </o-table>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Dashboard Permissions</v-subheader>
                  <o-table
                    :busy="busy"
                    type="array"
                    :data="dashboardData"
                    :headers="[
                      {
                        text: 'DASHBOARD',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                        type: 'text',
                      },
                      {
                        text: 'ENABLED',
                        align: 'start',
                        sortable: false,
                        value: 'value',
                        type: 'edit-boolean',
                        action: 'on-value-changed',
                      },
                    ]"
                    @on-value-changed="dashboardCheckChanged"
                  />
                </v-col>
                <v-col cols="6">
                  <v-subheader>Report Permissions</v-subheader>
                  <o-table
                    :busy="busy"
                    type="array"
                    :data="reportData"
                    :headers="[
                      {
                        text: 'REPORT NAME',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                        type: 'text',
                      },
                      {
                        text: 'ENABLED',
                        align: 'start',
                        sortable: false,
                        value: 'value',
                        type: 'edit-boolean',
                        action: 'on-value-changed',
                      },
                    ]"
                    @on-value-changed="reportCheckChanged"
                  />
                </v-col>
                <v-col cols="6">
                  <v-subheader>Report Field Permissions</v-subheader>
                  <o-table
                    :busy="busy"
                    type="array"
                    :data="reportFieldData"
                    :headers="[
                      {
                        text: 'FIELD NAME',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                        type: 'text',
                      },
                      {
                        text: 'ENABLED',
                        align: 'start',
                        sortable: false,
                        value: 'value',
                        type: 'edit-boolean',
                        action: 'on-value-changed',
                      },
                    ]"
                    @on-value-changed="reportFieldCheckChanged"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ManageRole",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Roles",
  },
  data() {
    return {
      title: "Roles",
      busy: false,
      action: "",
      roles: [],
      accounts: [],
      valid: false,
      parameters: {
        role_id: "", 
        name: "",          
        account_id: [],
        is_system: [],
        archived: [],
        shared_across_accounts: []
      },
      baseModel: {
        id: 0,
        account_id: 0,
        alternative_id: {},
        archived: false,
        dashboard_ids: [],
        role_id: 0,
        is_system: false,
        name: "",
        notes: "",
        parent_role_id: 0,
        permissions: {},
        report_field_group_ids: [],
        shared_across_accounts: false,
        report_ids: [],
        alt_account_id: 0,
        created_by: 0,
        updated_by: 0,
      },      
      permissionFilterValue: "",
      originalModel: {},
      model: {},
      permissions: [],
      permissionData: [],
      parentRoles: [],
      permissionNew: [],
      reportFieldData: [],
      reportFields: [],
      reportData: [],
      reports: [],
      dashboardData: [],
      dashboards: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no",
      "a.id",
      "a.name",
      "a.role_id",
      "a.parent_role_id",
      "a.dashboard_ids",
      "a.report_field_group_ids",
      "a.report_ids",
      "a.permissions",
      "a.shared_across_accounts",
      "a.is_system",
      "a.account_id",
      "b.account_name",
      "a.notes",
      "a.created_by",
      "a.created_at",
      "a.updated_by",
      "a.updated_at",
      "a.deleted_by",
      "a.deleted_at",
      "concat(c.last_name, ', ', c.first_name) as created_by_name",
      "concat(d.last_name, ', ', d.first_name) as deleted_by_name",
    ];
    this.listParameters.sources = {
      main: "roles a",
      children: [
        {
          name: "accounts b",
          on: "a.account_id = b.account_id",
        },
        {
          name: "users c",
          on: "a.created_by = c.id",
        },
        {
          name: "users d",
          on: "a.deleted_by = d.id",
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.deleted_at",
        dir: "DESC",
      },
      {
        sort: "a.updated_at",
        dir: "DESC",
      },
    ];
  },
  async mounted() {
    //this.reset();
    this.permissions = await this.getList(
      ["permission"],
      "permissions",
      [],
      [],
      "permission"
    );
    this.role = await this.getList(["role_id as id", "name"], "roles", [], [], "name");
    this.parentRoles = await this.getList(
      ["role_id as id", "name"],
      "roles",
      ["is_system = true"],
      [],
      "name"
    );
    this.parentRoles = [].concat([{ id: 0, name: "N/A" }], this.parentRoles);
    this.reports = await this.getList(["*"], "reports", [], [], "name");
    this.dashboards = await this.getList(["*"], "dashboards", [], [], "name");
    this.reportFields = await this.getList(
      ["*"],
      "report_fields",
      [],
      [],
      "name"
    );
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
      if (this.permissions.length > 0) {
        this.permissionData = this.permissions.map((d) => {
          return {
            object_name: d.permission,
            name: d.permission.replace(/[-_[\]{}()*+?,\\^$|#\s]/g, " "),
            create: false,
            read: false,
            update: false,
            delete: false,
            hidden: false
          };
        });
      }
      this.permissionData = [].concat(
        [
          {
            object_name: "select_all",
            name: "SELECT ALL",
            create: false,
            read: false,
            update: false,
            delete: false,
            hidden: false
          },
        ],
        this.permissionData
      );

      let dashboardData = this.dashboards.map((d) => {
        return {
          ...d,
          value: this.model.dashboard_ids.includes(d.dashboard_id),
        };
      });

      let selectAll = dashboardData.filter((d) => !d.value).length === 0;
      dashboardData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        dashboardData
      );
      this.dashboardData = dashboardData;

      let reportData = this.reports.map((d) => {
        return { ...d, value: this.model.report_ids.includes(d.report_id) };
      });
      selectAll = reportData.filter((d) => !d.value).length === 0;
      reportData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        reportData
      );
      this.reportData = reportData;

      let reportFieldData = this.reportFields.map((d) => {
        return {
          ...d,
          value: this.model.report_field_group_ids.includes(d.id),
        };
      });
      selectAll = reportFieldData.filter((d) => !d.value).length === 0;
      reportFieldData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        reportFieldData
      );
      this.reportFieldData = reportFieldData;
    },
    edit(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);

      this.permissionData = [];
      this.permissions
        .filter((d) => d.permission != "select_all")
        .forEach((item) => {
          const permission = {
            object_name: item.permission,
            name: item.permission,
            create: false,
            read: false,
            update: false,
            delete: false,
            hidden: false
          };
          const tmp = this.model.permissions.filter((d) => {
            return d.object_type === item.permission;
          });
          if (tmp.length > 0) {
            switch (tmp[0].permission) {
              case 1:
                permission.read = true;
                break;
              case 2:
                permission.create = true;
                break;
              case 3:
                permission.read = true;
                permission.create = true;
                break;
              case 4:
                permission.update = true;
              case 5:
                permission.read = true;
                permission.update = true;
              case 6:
                permission.create = true;
                permission.update = true;
                break;
              case 7:
                permission.read = true;
                permission.create = true;
                permission.update = true;
                break;
              case 8:
                permission.delete = true;
                break;
              case 9:
                permission.read = true;
                permission.delete = true;
                break;
              case 10:
                permission.create = true;
                permission.delete = true;
                break;
              case 11:
                permission.read = true;
                permission.create = true;
                permission.delete = true;
                break;
              case 12:
                permission.update = true;
                permission.delete = true;
                break;
              case 13:
                permission.read = true;
                permission.update = true;
                permission.delete = true;
                break;
              case 14:
                permission.create = true;
                permission.update = true;
                permission.delete = true;
                break;
              case 15:
                permission.read = true;
                permission.create = true;
                permission.update = true;
                permission.delete = true;
                break;
            }
          }
          this.permissionData.push(permission);
        });

      const create = this.permissionData.filter((d) => !d.create).length === 0;
      const read = this.permissionData.filter((d) => !d.read).length === 0;
      const update = this.permissionData.filter((d) => !d.update).length === 0;
      const remove = this.permissionData.filter((d) => !d.delete).length === 0;

      this.permissionData = [].concat(
        [
          {
            object_name: "select_all",
            name: "SELECT ALL",
            create,
            read,
            update,
            delete: remove,
            hidden: false
          },
        ],
        this.permissionData
      );

      let dashboardData = [];
      this.dashboards
        .filter((d) => d.name != "select_all")
        .forEach((d) => {
          dashboardData.push({
            ...d,
            value: this.model.dashboard_ids.includes(d.id),
          });
        });
      let selectAll = dashboardData.filter((d) => !d.value).length === 0;
      dashboardData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        dashboardData
      );
      this.dashboardData = dashboardData;

      let reportData = this.reports.map((d) => {
        return { ...d, value: this.model.report_ids.includes(d.id) };
      });
      selectAll = reportData.filter((d) => !d.value).length === 0;
      reportData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        reportData
      );
      this.reportData = reportData;

      let reportFieldData = this.reportFields.map((d) => {
        return {
          ...d,
          value: this.model.report_field_group_ids.includes(d.id),
        };
      });
      selectAll = reportFieldData.filter((d) => !d.value).length === 0;
      reportFieldData = [].concat(
        [{ name: "SELECT ALL", value: selectAll }],
        reportFieldData
      );
      this.reportFieldData = reportFieldData;

      this.action = "edit";
    },
    async remove(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.account_name} (${this.model.account_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(`/rest/roles/${this.model.id}`, null)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.originalModel.account_name} (${this.originalModel.account_id}) was successfully deleted.`,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                errors.push(err.data.message);
                this.model = this.baseModel;
                this.originalModel = this.baseModel;
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/roles";
      this.model.dashboard_ids = this.dashboardData
        .filter((d) => d.dashboard_id > 1 && d.value)
        .map((d) => d.dashboard_id);
      this.model.report_ids = this.reportData
        .filter((d) => d.report_id > 1 && d.value)
        .map((d) => d.report_id);
      this.model.report_field_group_ids = this.reportFieldData
        .filter((d) => d.id > 1 && d.value)
        .map((d) => d.id);

      this.model.permissions = this.permissionData
        .filter((d) => d.object_name !== "select_all")
        .map((o) => {
          const read_cnt = o.read ? 1 : 0;
          const create_cnt = o.create ? 2 : 0;
          const update_cnt = o.update ? 4 : 0;
          const delete_cnt = o.delete ? 8 : 0;

          const permission_cnt =
            read_cnt + create_cnt + update_cnt + delete_cnt;

          return {
            object_type: o.object_name,
            permission: permission_cnt,
          };
        });

      ///this.model.account_id = this.loggedInUser.account_id;
      this.model.platform_id = this.loggedInUser.platform_id;
      if (this.model.is_system) {
        this.model.parent_role_id = 0;
      }

      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${response.data.name} (${response.data.role_id}).`,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        const model = _.cloneDeep(this.model);
        if (this.model.name === this.originalModel.name) {
          delete this.model.name;
        }
        if (this.model.created_by < 1) {
          this.model.created_by = this.loggedInUser.id;
        }
        this.model.updated_by = this.loggedInUser.id;
        await this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated ${model.name} (${model.id}).`,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
      } else {
        this.reset();
      }
    },
    reset(par) {
      if (par) {
        this.parameters = {
          role_id: "", 
          name: "",          
          account_id: [],
          is_system: [],
          archived: [],
          shared_across_accounts: []
        }
      }

      this.listParameters.wheres = [];
      const wheres = [];      
      if (this.parameters.role_id != '') {
         wheres.push(`a.role_id::text ilike '%${this.parameters.role_id}%'`)
      }
      if (this.parameters.name != '') {
         wheres.push(`a.name ilike '%${this.parameters.name}%'`)
      }
      if (this.parameters.account_id.length > 0) {
         wheres.push(`a.account_id in (${this.parameters.account_id.join(',')})`)
      }
      if (this.parameters.is_system.length > 0) {
         wheres.push(`a.is_system in (${this.parameters.is_system.join(',')})`)
      } 
      if (this.parameters.archived.length > 0) {
         wheres.push(`a.archived in (${this.parameters.archived.join(',')})`)
      } 
      if (this.parameters.shared_across_accounts.length > 0) {
         wheres.push(`a.shared_across_accounts in (${this.parameters.shared_across_accounts.join(',')})`)
      } 
      if (wheres.length > 0) this.listParameters.wheres = [wheres.join(' OR ')];

      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      this.originalModel = _.cloneDeep(this.baseModel);

      //this.changeQueryString(this.parameters)

      setTimeout(async () => {
        await this.$refs.table.refresh();
      }, 200);
    },
    permissionCheckChanged(e) {
      const value = e.value[e.key];
      const field = e.value.object_name;

      if (field !== "select_all") {
        this.permissionData[0][e.key] =
          this.permissionData.filter(
            (d) => !d.value && d.object_name !== "select_all"
          ).length === 0;
      }
      if (field === "select_all") {
        this.permissionData.forEach((d, i) => {
          if (i > 0) {
            d[e.key] = value;
          }
        });
      }
    },
    dashboardCheckChanged(e) {
      const value = e.value[e.key];
      const field = e.value.name;

      if (field !== "SELECT ALL") {
        //this.dashboardData[0][e.key] = value;
        this.dashboardData[0][e.key] =
          this.dashboardData.filter((d) => !d.value && d.name !== "SELECT ALL")
            .length === 0;
      }
      if (field === "SELECT ALL") {
        this.dashboardData.forEach((d, i) => {
          if (i > 0) {
            d[e.key] = value;
          }
        });
      }
    },
    reportCheckChanged(e) {
      const value = e.value[e.key];
      const field = e.value.name;

      if (field !== "SELECT ALL") {
        this.reportData[0][e.key] =
          this.reportData.filter((d) => !d.value && d.name !== "SELECT ALL")
            .length === 0;
      }
      if (field === "SELECT ALL") {
        this.reportData.forEach((d, i) => {
          if (i > 0) {
            d[e.key] = value;
          }
        });
      }
    },
    reportFieldCheckChanged(e) {
      const value = e.value[e.key];
      const field = e.value.name;

      if (field !== "SELECT ALL") {
        this.reportFieldData[0][e.key] =
          this.reportFieldData.filter(
            (d) => !d.value && d.name !== "select_all"
          ).length === 0;
      }
      if (field === "SELECT ALL") {
        this.reportFieldData.forEach((d, i) => {
          if (i > 0) {
            d[e.key] = value;
          }
        });
      }
    },
    async fillPermissions() {
       const roles  = await this.getList(
        ["a.permissions"],
         "roles a",
        [`a.role_id = ${this.model.parent_role_id}`],
        [],
        "a.id"
      );
      let model = _.cloneDeep(this.model);
      if (roles.length > 0) {
        model = roles[0];
        if (this.permissions.length > 0) {
          this.permissionData = this.permissions.map((d) => {
            return {
              object_name: d.permission,
              name: d.permission.replace(/[-_[\]{}()*+?,\\^$|#\s]/g, " "),
              create: false,
              read: false,
              update: false,
              delete: false,
              hidden: false
            };
          });
        }
      } 

      this.permissionData = [];
      this.permissions
        .filter((d) => d.permission != "select_all")
        .forEach((item) => {
          const permission = {
            object_name: item.permission,
            name: item.permission,
            create: false,
            read: false,
            update: false,
            delete: false,
            hidden: false
          };
          const tmp = model.permissions.filter((d) => {
            return d.object_type === item.permission;
          });
          if (tmp.length > 0) {
            switch (tmp[0].permission) {
              case 1:
                permission.read = true;
                break;
              case 2:
                permission.create = true;
                break;
              case 3:
                permission.read = true;
                permission.create = true;
                break;
              case 4:
                permission.update = true;
              case 5:
                permission.read = true;
                permission.update = true;
              case 6:
                permission.create = true;
                permission.update = true;
                break;
              case 7:
                permission.read = true;
                permission.create = true;
                permission.update = true;
                break;
              case 8:
                permission.delete = true;
                break;
              case 9:
                permission.read = true;
                permission.delete = true;
                break;
              case 10:
                permission.create = true;
                permission.delete = true;
                break;
              case 11:
                permission.read = true;
                permission.create = true;
                permission.delete = true;
                break;
              case 12:
                permission.update = true;
                permission.delete = true;
                break;
              case 13:
                permission.read = true;
                permission.update = true;
                permission.delete = true;
                break;
              case 14:
                permission.create = true;
                permission.update = true;
                permission.delete = true;
                break;
              case 15:
                permission.read = true;
                permission.create = true;
                permission.update = true;
                permission.delete = true;
                break;
            }
          }
          this.permissionData.push(permission);
        });

      const create = this.permissionData.filter((d) => !d.create).length === 0;
      const read = this.permissionData.filter((d) => !d.read).length === 0;
      const update = this.permissionData.filter((d) => !d.update).length === 0;
      const remove = this.permissionData.filter((d) => !d.delete).length === 0;

      this.permissionData = [].concat(
        [
          {
            object_name: "select_all",
            name: "SELECT ALL",
            create,
            read,
            update,
            delete: remove,
          },
        ],
        this.permissionData
      );
      
    },
    permissionFilter() {
      for (const p of this.permissionData) {
        if (this.permissionFilterValue !== '' && p.object_name !== 'select_all') {
          p.hidden = !(p.name.includes(this.permissionFilterValue))
        } else {
          p.hidden = false;
        }
      } 
      
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
