<template>
  <ManageRole dialog-name="Role" />
</template>
<script>
import ManageRole from '@/views/app/manage/Role'

export default {
  name: 'RoleWrapper',
  components: {
    ManageRole
  }
}
</script>
